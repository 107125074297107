<template>
  <div>
    <div style="display: flex;justify-content: space-between;align-items: center">
      <div class="header-title">
        我的机构/工作室
      </div>
      <div class="add-company" @click="$router.push('/certification')">
        ＋认证更多机构
      </div>
    </div>
    <el-table fit header-cell-class-name="header-table" :data="tableList" style="margin-top: 15px">
      <el-table-column label="机构名称" prop="name"></el-table-column>
      <el-table-column label="机构类型" prop="companyType" :formatter="(row, column, cellValue) => ['', '合约机构', '认证机构'][cellValue]"></el-table-column>
      <el-table-column label="身份" prop="companyRoleName"></el-table-column>
      <el-table-column label="成员人数" prop="userNum"></el-table-column>
      <el-table-column label="加入时间" prop="submitTime" :formatter="(row, column, cellValue) => $timestampToString(cellValue)"></el-table-column>
      <el-table-column label="操作" v-slot="{row}">
        <el-button type="text" @click="handleSwitchCompany(row)" v-if="row.id !== user.userCompanyVo.id">
          查看
        </el-button>
        <div v-else style="min-height: 40px;display: inline-block"></div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getCompanyList} from "@/api"

export default {
  name: "companyCertification",

  data() {
    return {
      tableList: []
    }
  },

  mounted() {
    this.getCompanyList()
  },

  methods: {
    handleSwitchCompany(row){
      this.$post('/api/company/identityChange', {id: row.id, type: 1}).then((res) => {
        let data = res.data
        if (res.code === 0) {
          this.$store.commit('LOGIN', {
            secret: data.secret,
            session: data.session,
            user: data.user
          })
          this.$post('/api/user/userMsg').then(res => {
            this.$store.commit('hiddenBut', res.data)
            this.$store.commit('UMSG', res.data)
            this.$store.state.release = true;
            if(data.user.webLoginType === 1){
              if(data.user?.userCompanyVo?.status !== 1 ){
                this.$router.push('/certification/examine');
              }else {
                this.$router.replace('/create').catch(() => {
                  console.log('路由重复跳转');
                });
              }
              this.$popup({msg: '身份切换成功', type: 'success'})
            }else {
              if([1,2].includes(data.verifiedStatus)){
                this.$router.push('/Musiciancon')
              }else {
                this.$router.replace('/').catch(() => {
                  console.log('路由重复跳转');
                });
              }
            }
          })
        }
      })
    },
    getCompanyList(){
      getCompanyList().then(r => {
        if (r.code === 0 && r.data.length > 0){
          this.tableList = r.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .header-table {
  height: 48px;
  background: #f7f7f7 !important;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium,system-ui;
  font-weight: 500;
  color: #555555;
}
.header-title{
  font-size: 15px;
  font-family: PingFang SC, PingFang SC-Regular,system-ui;
  color: #333333;
}

.add-company {
  margin-left: 24px;
  width: 122px;
  height: 40px;
  background: rgba(255, 0, 77, .1);
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular,system-ui;
  font-weight: 400;
  color: #ff004d;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
</style>
